import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import LearnMore from "@components/learnMore"
import KeyPoints from "@components/keyPoints"
import PatientVoice from "@components/PatientVoice"
import InlineVideo from "@components/inlineVideo"
import InlineVideoSection from "@components/inlineVideoSection"
import Anchor from "@components/Anchor"
import Callout from "@components/callout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Section from "@components/section"
import { Heading2 } from "@styles/ui"

const learnMoreCtas = [
  {
    text: "Reasons to consider XYWAV to treat your Idiopathic Hypersomnia",
    url: "/idiopathic-hypersomnia/what-is-xywav/",
  },
  {
    text: "Find out how XYWAV was studied in IH symptoms",
    url: "/idiopathic-hypersomnia/xywav-efficacy/",
  },
]

const keyPointStyles = tw`text-sm leading-[20px] lg:(text-xl leading-[25px])`

const keyPoints = [
  {
    title: "Excessive Daytime Sleepiness",
    image: "/images/icons/excessive-daytime-sleepiness-icon.svg",
    imageAlt: "Excessive daytime sleepiness icon",
    text: (
      <div css={keyPointStyles}>
        <span tw="block mb-3.5">
          Feeling very sleepy or tired during the day
        </span>
        <ul tw="list-disc text-left [padding-inline-start: 25px] [padding-inline-end: 10px] mr-1">
          <li>Most common symptom of Idiopathic Hypersomnia</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Sleep Inertia",
    imageAlt: "Sleep inertia icon",
    image: "/images/icons/sleep-inertia-icon.svg",
    text: (
      <div css={keyPointStyles}>
        <span tw="block mb-3.5">
          Severe grogginess or confusion when waking up regardless of how much
          sleep you get or how many alarms you&nbsp;set
        </span>
        <ul tw="list-disc text-left [padding-inline-start: 25px] [padding-inline-end: 10px] mr-1">
          <li>
            Feeling disoriented, irritable, or even clumsy when waking up and
            for hours afterwards
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Cognitive Impairment",
    imageAlt: "Cognitive impairment icon",
    image: "/images/icons/cognitive-impairment-icon.svg",
    text: (
      <div css={keyPointStyles}>
        <span tw="block mb-3.5">
          Difficulty focusing, trouble thinking clearly, or memory problems due
          to excessive daytime sleepiness
        </span>
        <ul tw="list-disc text-left [padding-inline-start: 25px] [padding-inline-end: 10px] mr-1">
          <li>Often described as &quot;brain fog&quot;</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Long Sleep Time",
    imageAlt: "Alarm clock icon",
    image: "/images/icons/alarm-clock-icon.svg",
    text: (
      <div css={keyPointStyles}>
        <span tw="block mb-3.5">
          Ability to sleep for many hours of the day
        </span>
        <ul tw="list-disc text-left [padding-inline-start: 25px] [padding-inline-end: 10px] mr-1">
          <li>Sometimes for 11 hours or more</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Non-Restorative Sleep",
    image: "/images/icons/sleepy-eye-icon.svg",
    imageAlt: "Sleepy eye icon",
    text: (
      <div css={keyPointStyles}>
        <span tw="block mb-3.5">
          Not feeling rested when waking up, despite sleeping a typical (or more
          than typical) amount each night
        </span>
      </div>
    ),
  },
  {
    title: "Long, Unrefreshing Naps",
    image: "/images/icons/person-napping-icon.svg",
    imageAlt: "Person napping in bed icon",
    text: (
      <div css={keyPointStyles}>
        <span tw="block mb-3.5">
          Not feeling refreshed or better after napping (may even feel worse)
        </span>
      </div>
    ),
  },
]

const AboutIH = ({ location, data }) => {
  return (
    <Layout location={location}>
      <Seo
        title="What is Idiopathic Hypersomnia? Learn about IH Symptoms | XYWAV® for IH"
        description="Understand the symptoms of Idiopathic Hypersomnia (IH) like excessive daytime sleepiness, sleep inertia, and cognitive impairment. Download the Idiopathic Hypersomnia Severity Scale (IHSS) to help you learn more on this sleep disorder."
        location={location}
      />

      <StaticHero
        eyebrow="What is IH?"
        imageData={data.heroImage}
        alt="Man holding a basketball laughing with a friend"
        jumpLinks={[
          { text: "What is Idiopathic Hypersomnia?", link: "#what-is-ih" },
          { text: "Symptoms of Idiopathic Hypersomnia", link: "#symptoms" },
        ]}
        bgImgSm
        gradient="purple"
      >
        Understanding your Idiopathic Hypersomnia (IH)
      </StaticHero>

      <Section id="what-is-ih">
        <Container>
          <h2 css={[Heading2, tw`mb-5 md:(mb-12)`]}>
            Idiopathic Hypersomnia is a rare, lifelong sleep disorder.
          </h2>
          <div tw="md:(flex gap-7 items-center)">
            <div tw="hidden md:(block)">
              <GatsbyImage
                tw="hidden md:(block w-[470px])"
                image={getImage(data.whatIsIhImage)}
                alt="Woman working on laptop"
              />
            </div>

            <div>
              <p tw="font-bold mb-4 md:mb-6">What does that mean?</p>
              <p tw="font-bold mb-4 md:mb-6">
                Not many people know about Idiopathic Hypersomnia, which is why
                it is often mistaken for other conditions. Family, friends, and
                even doctors may need help understanding the symptoms and how it
                is different.
              </p>
              <ul tw="list-disc [padding-inline-start: 25px] mr-1 text-sm lg:(text-xl leading-[1.4])">
                <li>
                  <span tw="font-extrabold">Idiopathic</span> = The cause is
                  unknown. Any disease or condition with an unknown source may
                  be called idiopathic.
                </li>
                <li tw="mt-4">
                  <span tw="font-extrabold">Hypersomnia</span> = A condition
                  that causes you to feel very sleepy during the day and/or
                  makes you need excessive amounts of sleep.
                </li>
                <li tw="mt-4">
                  <span tw="font-extrabold">Sleep disorder</span> = There is a
                  problem with the quality, timing, or amount of sleep.
                </li>
              </ul>
              <p tw="mt-4">
                An Idiopathic Hypersomnia diagnosis can be confirmed by
                identifying key symptoms and completing a sleep study
                (PSG/MSLT). Often, a doctor specializing in sleep medicine can
                help guide this&nbsp;process.
              </p>
            </div>
          </div>
        </Container>
      </Section>

      <Section bgColour={tw`bg-lilac`} id="symptoms">
        <img
          src={"/images/xywav-logo-large-callout.svg"}
          tw="hidden absolute opacity-50 w-1/2 min-w-[500px] top-0 right-0 bottom-0 left-[-180px] z-10 object-cover md:block"
          alt=""
        />
        <Container>
          <h2 css={[Heading2, tw`text-center mb-6`]}>
            Idiopathic Hypersomnia is more than just feeling tired during the
            day.
          </h2>
          <p tw="text-center mb-10 md:(mb-7)">
            It can include a range of symptoms.
          </p>
          <KeyPoints points={keyPoints} mdWrap />
        </Container>
      </Section>

      {/* Quote */}
      <PatientVoice
        quote={
          <>
            Getting diagnosed with Idiopathic Hypersomnia was a wake-up call for
            me. I finally had a reason why it was so hard to get up and get
            going in the morning. I was sleeping so much, but it never felt like
            enough.&rdquo;
          </>
        }
        patient={"William"}
        diagnosis={"XYWAV IH Ambassador"}
        imageData={data}
        altText={
          "Patient William sharing how XYWAV® helped his Idiopathic Hypersomnia"
        }
        cta={{
          text: "More on talking to your doctor about Idiopathic Hypersomnia",
          url: "/idiopathic-hypersomnia/talking-to-your-doctor/",
        }}
        theme="ih"
        lilacBg
      />

      <Callout
        heading="Idiopathic Hypersomnia Severity Scale (IHSS)"
        cta={{
          text: "Download IHSS",
          url: "/pdf/IHSS_Downloadable_PDF.pdf",
          external: true,
          noInterstitial: true,
        }}
      >
        <>
          <p tw="xl:mb-8 sm:mb-5">
            The IHSS was created by sleep experts to help determine the severity
            and frequency of symptoms in people with Idiopathic Hypersomnia and
            provide a measurement that may be useful for follow-up visits with a
            sleep specialist.
          </p>
          <p>
            Use the IHSS to rate your symptoms on a 3- or 4-point scale for each
            question and review your responses with your doctor.
          </p>
        </>
      </Callout>

      {/* Video */}
      <InlineVideoSection
        video={
          <InlineVideo
            wistiaId="ak3pturfle"
            thumbnail={data.thumbImage}
            tall
            videoTitle="Diana, a real patient and XYWAV IH Ambassador, recalls her journey to an Idiopathic Hypersomnia diagnosis."
            alt="Patient Jennie recalling her patient journey"
          />
        }
      >
        <p tw="mb-4 md:mb-6">
          Diana, a real patient and XYWAV IH Ambassador, recalls her journey to
          an Idiopathic Hypersomnia diagnosis.
        </p>
        <div tw="flex justify-center md:justify-start">
          <Anchor
            link="/idiopathic-hypersomnia/resources/"
            hasArrow
            addedStyles={tw`font-bold text-sm text-left md:text-xl`}
          >
            Hear more from Diana and
            <br />
            other XYWAV IH Ambassadors
          </Anchor>
        </div>
      </InlineVideoSection>

      {/* Call out */}
      <Callout
        heading="Stay in the loop!"
        cta={{
          text: "SIGN UP NOW",
          url: "/idiopathic-hypersomnia/stay-connected/",
        }}
      >
        <>
          <p tw="xl:mb-8 sm:mb-5">
            We&apos;re here to support you with information on Idiopathic
            Hypersomnia, starting XYWAV, tips for talking with your doctor,
            JazzCares<sup>&reg;</sup>, and more. Join our email list today.
          </p>
        </>
      </Callout>

      <LearnMore ctas={learnMoreCtas} ih colour="purple"></LearnMore>
    </Layout>
  )
}

export default AboutIH

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "what-is-ih/man-holding-basketball.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    whatIsIhImage: file(
      relativePath: { eq: "what-is-ih/woman-working-on-laptop.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 470
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    patientVoice: file(
      relativePath: { eq: "what-is-ih/patient-william-ih-ambassador-quote.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 705, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: {
        eq: "what-is-ih/patient-william-ih-ambassador-quote-mobile.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    thumbImage: file(
      relativePath: {
        eq: "video-thumbnails/patient-jennie-recalling-her-journey.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
  }
`
