import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"

const Callout = ({
  children,
  heading,
  cta,
  sourceText,
  id,
  gradient,
  noPadding,
}) => {
  const gradientBg =
    gradient === "orange"
      ? tw`gradientOrange`
      : gradient === "colourful"
      ? tw`agnostic-hero-gradient`
      : gradient === "red"
      ? tw`gradientRed`
      : tw`bg-gradient-purple-callout`

  return (
    <section
      id={id}
      css={[tw`py-7 lg:py-14`, gradientBg, id && tw`lg:scroll-mt-[80px]`]}
    >
      <Container>
        <div css={[tw`px-2 md:(px-0)`, noPadding && tw`px-0`]}>
          <h2
            css={[
              tw`text-white font-cocon text-center font-bold text-4xl mb-7 leading-tight xl:(text-5xl)`,
              !children && !cta && !sourceText && tw`mb-0`,
            ]}
          >
            {heading}
          </h2>
          {children && (
            <div
              css={[
                tw`text-white text-center text-xl leading-tight`,
                cta && tw` mb-7`,
              ]}
            >
              {children}
            </div>
          )}
          {cta && (
            <div tw="text-center">
              <PrimaryCta
                url={cta.url}
                isExternal={cta.external}
                noInterstitial={cta.noInterstitial}
                mdSmall={cta.mdSmall !== undefined ? cta.mdSmall : true}
                width={cta.width || null}
              >
                {cta.text}
              </PrimaryCta>
            </div>
          )}
          {sourceText && <div tw="text-white mt-6 lg:(mt-6)">{sourceText}</div>}
        </div>
      </Container>
    </section>
  )
}

export default Callout

Callout.propTypes = {
  heading: PropTypes.node,
  cta: PropTypes.object,
  sourceText: PropTypes.node,
  noPadding: PropTypes.bool,
}
Callout.defaultProps = {
  heading: "",
  cta: null,
  sourceText: null,
  noPadding: false,
}
