import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

// Components
import { Heading2 } from "@styles/ui"

const InlineVideoSection = ({ video, bgColour, children, title = null }) => {
  return (
    <section css={[tw`pt-7 lg:(py-12)`, bgColour && bgColour]}>
      <div tw="flex flex-col lg:(flex-row justify-center items-center gap-8 max-w-5xl mx-auto px-4) xl:(px-0 gap-[130px] max-w-6xl)">
        <div tw="order-2  lg:min-w-[670px]">{video}</div>
        <div tw="order-1 text-center mb-7 px-4 lg:(text-left mb-0 order-2 max-w-[370px] px-0)">
          <h2 css={[Heading2, tw`text-studio mb-3.5 xl:(mb-6)`]}>
            {title ? title : "Watch video"}
          </h2>
          {children}
        </div>
      </div>
    </section>
  )
}

export default InlineVideoSection

InlineVideoSection.propTypes = {
  video: PropTypes.object.isRequired,
  bgColour: PropTypes.object,
}
InlineVideoSection.defaultProps = {
  bgColour: null,
}
